<template>
  <layoutDefault>
    <TasksSidebar/>
    <div ref="tasks" class="tasks flex fdc w100">
      <div class="tasks__content-container flex w100">
        <TasksPanel/>
      </div>
    </div>
  </layoutDefault>
  <Modal :isVisible="isModalProjectsVisible" :key="'ModalProjects'" :isClosable="true"
         @close="isModalProjectsVisible = false">
    <ModalProjects/>
  </Modal>
  <Modal :isVisible="isModalTeamVisible" :key="'ModalTeam'" :isClosable="true"
         @close="isModalTeamVisible = false">
    <ModalTeam/>
  </Modal>
  <Modal :isVisible="modalProfile.active" :key="'ModalProfile'" :isClosable="modalProfile.closable"
         @close="modalProfile.active = false">
    <ModalProfile/>
  </Modal>
</template>

<script>
import config from 'config';
import {commonHelper} from '@/_helpers/common-helper';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import apiService from '@/_services/api.service';

import LayoutDefault from '@/layouts/layoutDefault/LayoutDefault';
import TasksSidebar from '@/components/tasks-sidebar/TasksSidebar';
import TasksPanel from '@/components/tasks-panel/TasksPanel';
import TasksRightSidebar from '@/components/tasks-right-sidebar/TasksRightSidebar';

import Modal from '@/components/modal/Modal';
import ModalTeam from '@/components/modal-team/ModalTeam';
import ModalProfile from '@/components/modal-profile/ModalProfile';
import ModalProjects from '@/components/modal-projects/ModalProjects';

export default {
  name: 'Tasks',
  data: () => {
    return {
      isModalProjectsVisible: false,
      isModalTeamVisible: false,
      isInitialized: false,
    }
  },
  created() {
    commonHelper.documentTitle( 'tickets @ dashboard', 'QA Tool' );
    const initialSettings = this.getInitialSettings;
    this.setActiveTeamId(initialSettings.team.team_id);
    this.setActiveProjectId(initialSettings.project.project_id);
    window.addEventListener('keydown', this.handleKeyPress);

    if( this.userIsOwner && 'true' === localStorage.getItem( 'firstEntry' ) ) {
      this.isModalTeamVisible = true;
    }
  },
  async mounted() {
    this.checkEmail();
    this.fetchTeams();
    await this.fetchProjects();

    if (this.$route.name === 'Project' || this.$route.name === 'Task') {
      let project = this.getProjectByPrefix(this.$route.params.projectPrefix);
      if ( project && project.project_id ) {
        this.setActiveProjectId( project.project_id );
      }
    }

    await this.fetchTasks();
    this.$refs.tasks.focus();

    if (this.$route.name === 'Task' && this.$route.params.taskId) {
      let tasks = this.getTasks.tasks;
      let needleTask = tasks.find(t => parseInt(t.id) === parseInt(this.$route.params.taskId));

      if (needleTask) {
        this.setActiveProjectId(needleTask.project.id);
        this.setActiveTaskId(needleTask.id);
      } else {
        this.setSingleTaskIsNotFound(true);
      }
    }
  },
  components: {
    ModalProjects,
    ModalProfile,
    ModalTeam,
    Modal,
    TasksPanel,
    TasksSidebar,
    LayoutDefault,
    TasksRightSidebar,
  },
  methods: {
    ...mapActions(
      {
        fetchInitialSettings: 'user/fetchInitialSettings',
        fetchTeams: 'teams/fetchTeams',
        fetchTasks: 'tasks/fetchTasks',
        fetchProjects: 'projects/fetchProjects',
        fetchComments: 'comments/fetchComments',
        fetchTaskById: 'tasks/fetchTaskById',
      }
    ),
    ...mapMutations(
      {
        setActiveTaskId: 'tasks/setActiveTaskId',
        switchTask: 'tasks/switchTask',
        setComments: 'comments/setComments',
        setTasks: 'tasks/setTasks',
        setAddProjectModalState: 'common/setAddProjectModalState',
        setActiveTeamId: 'teams/setActiveTeamId',
        setActiveProjectId: 'projects/setActiveProjectId',
        setNotification: 'notifications/setNotification',
        removeNotifications: 'notifications/removeNotifications',
        setSingleTaskIsNotFound: 'tasks/setSingleTaskIsNotFound'
      }
    ),
    checkEmail() {
      let initialSettings = this.getInitialSettings,
          query = this.$route.query;

      if (initialSettings.user && true !== initialSettings.user.email_is_confirmed) {
        this.setNotification({
          title: 'Confirm your email',
          desc: 'You have received Email to confirm your registration. Follow the link with your email and complete the registration.',
          type: 'warning',
        });
      }

      ( async () => {
        if (typeof query.action !== 'undefined' && 'confirm-email' === query.action) {
          let form = new FormData;
          form.append('user_id', query.user_id);
          form.append('user_key', query.user_key);

          await apiService.post('users/confirm_email', form)
            .then(response => {
              if (true === response.data.confirmed) {
                this.fetchInitialSettings();
                this.removeNotifications();
                this.modalProfile.active = true;
                this.modalProfile.closable = false;
                this.$router.replace({'query': null});
              }
            });
        }

        let user = this.getInitialSettings.user;
        if (true === user.email_is_confirmed && !user.profile_is_filled) {
          this.modalProfile.active = true;
          this.modalProfile.closable = false;
        }
      } )();
    },
    initWidget() {
      const scriptWidget = document.getElementById('rgbc-qat-widget');
      //Do not init if script already exist
      if (scriptWidget) {
        return;
      }
      const widget = document.createElement('script');
      const script = document.createTextNode(`
          (function (w, d, s, o, f) {
                  w['JS-Widget'] = o;
                  w[o] = w[o] || function () {
                      (w[o].q = w[o].q || []).push(arguments)
                  };
                  let js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
                  js.id = o;
                  js.src = f;
                  js.async = true;
                  fjs.parentNode.insertBefore(js, fjs);
              }(window, document, 'script', 'mw', '/widget/bootstrap.js'));
              mw(
                  'init_instantly',
                  {
                      project_id: "${this.testProject.project_id}",
                      app_url: "/widget/app.js",
                      api_url: "${config.apiUrl}",
                      pin_code: "${this.testProject.pincode}",
                      try_me_message: true
                  }
            );
      `);
      widget.appendChild(script);
      widget.setAttribute('id', 'rgbc-qat-widget');
      document.head.appendChild(widget);
    },
    handleKeyPress(event) {
      switch (event.keyCode) {
        case 38:
          event.preventDefault();
          this.switchTask('previous');
          break;
        case 40:
          event.preventDefault();
          this.switchTask('next');
          break;
      }
    },
    openModalProfile() {
      this.isModalVisible = true;
    }
  },
  computed: {
    ...mapGetters(
        {
          getActiveTask: 'tasks/getActiveTask',
          getTasks: 'tasks/getTasks',
          getActiveProject: 'projects/getActiveProject',
          getProjects: 'projects/getProjects',
          filterByTasksStatus: 'tasksFilters/getFilterByStatus',
          addProjectModalState: 'common/getAddProjectModalState',
          userIsOwner: 'user/userIsOwner',
          getInitialSettings: 'user/getInitialSettings',
          getActiveTeam: 'teams/getActiveTeam',
          getNotifications: 'notifications/getNotifications',
          modalProfile: 'common/getModalProfile',
          getProjectByPrefix: 'projects/getProjectByPrefix'
        }
    ),
    testProject: function () {
      let project_ = null;
      const projects = this.getProjects;
      if (projects && projects.projects) {
        for (const project of projects.projects) {
          if (project.is_test_project) {
            project_ = project;
          }
        }
      }
      return project_;
    }
  },
  watch: {
    testProject (project) {
      if (project && project.project_id && !this.isInitialized) {
        this.initWidget();
        this.isInitialized = true;
      }
    },
    getActiveTask (task) {
      if (task) {
        this.setComments([]);
        this.fetchComments();
      }
    },
    getActiveProject: function () {
      this.setActiveTaskId(null);
      this.fetchTasks();
    },
    getActiveTeam (team) {
      //clickup integration
      if (team) {
        const code = this.$route.query.code;
        const referrerIsClickup = document.referrer.search(team.integrations.clickup.api_url) !== -1;
        if (code && referrerIsClickup) {
          let payload = this.getActiveTeam;
              payload.code = code;
          this.$store.dispatch( 'teamsIntegration/fetchIntegration', payload )
            .then( response => {
              if ( response.status === 200 ) {
                this.fetchTeams();
                this.$router.replace({'query': null});
                this.isModalProjectsVisible = true;
              }
            } );
        }
      }
    },
    getTasks (tasks) {
      let initialSettings = this.getInitialSettings;
      if (tasks && tasks.tasks && initialSettings.showTryMeMessage !== 'false') {
        for (const task of tasks.tasks) {
          if (!task.is_test_task) {
            localStorage.setItem('showTryMeMessage', 'false');
          }
        }
      }
    },
    filterByTasksStatus () {
      this.fetchTasks();
      this.setActiveTaskId(null);
    },
  }
}
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>